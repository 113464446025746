import english from "Languages/english";
import AOS from "aos";
import "aos/dist/aos.css";
import useStorage from "hooks/useStorage";
import { Routes } from "pages/routes";
import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "styles/globalStyle";
import { AppContainer } from "styles/styledComponents";
import dark from "themes/dark";

function App() {
  const [theme, setTheme] = useStorage("theme", dark);
  const [, setLanguage] = useStorage("language", english);

  useEffect(() => {
    setTheme(dark);
    setLanguage(english);
  }, []);

  useEffect(() => {
    AOS.init({
      easing: "ease",
      duration: 750,
      once: true,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppContainer name="appContainer">
        <Routes />
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
