import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  transition: all 0.5s ease;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  top: 0;
  left: 0;

  .logo {
    h1 {
      font-size: var(--super);
      background: linear-gradient(
        to right,
        #5aeaf0 0%,
        #5ab8f0 20%,
        #5a67f0 40%,
        #5a67f0 60%,
        #5aeaf0 80%
      );
      background-size: 200% auto;

      color: #000;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: shine 3.5s ease infinite;
      @keyframes shine {
        to {
          background-position: 200% center;
        }
      }
    }
  }

  .bar {
    width: 400px;
    height: 15px;
    border-radius: 20px;
    background-color: var(--skeleton);
    margin-top: 20px;
    position: relative;

    :after {
      content: "";
      position: absolute;
      transition: all 1s ease;
      width: ${(props) => `${props.width}%`};
      border-radius: 20px;
      height: 15px;
      top: 0;
      left: 0;
      background: linear-gradient(
        to right,
        #5aeaf0 0%,
        #5ab8f0 20%,
        #5a67f0 40%,
        #5a67f0 60%,
        #5aeaf0 80%
      );

      background-size: 200% auto;
      animation: shine 2.5s ease infinite;
      @keyframes shine {
        to {
          background-position: 200% center;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .logo {
      h1 {
        font-size: var(--title);
      }
    }
    .bar {
      width: 300px;
      height: 10px;

      :after {
        height: 10px;
      }
    }
  }
`;
