import common from "Languages/common";
import { curriculum } from "config";
import { Banner, Buttons, Container, Info, Overlay, Wrapper } from "./styles";

const Hero = ({ heroRef, scrollTo, language }) => {
  const { title, subtitle, buttons } = language.hero;

  return (
    <Container name="container" ref={heroRef}>
      <Overlay />
      <Wrapper name="wrapper">
        <Banner name="banner" className="flex_cc">
          <Info name="info" data-aos="fade-up" data-aos-delay="2000">
            <h1>
              {title}
              <span className="gradient">{common.myName}</span>
            </h1>
            <p>{subtitle}</p>
            <Buttons name="buttons">
              <a href={curriculum}>
                <button className="download">{buttons.download}</button>
              </a>
              <button className="projects" onClick={() => scrollTo(3)}>
                {buttons.projects}
              </button>
            </Buttons>
          </Info>
        </Banner>
      </Wrapper>
    </Container>
  );
};

export default Hero;
