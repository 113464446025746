import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 200;

  button {
    background-color: var(--background);
    border-radius: 20px;
    color: var(--light);
    margin-left: 10px;
    padding: 2px 15px;

    :hover {
      background-color: var(--modal);
    }
  }

  .active {
    background-color: var(--primary);
    color: var(--light);
    cursor: default;

    :hover {
      background-color: var(--primary);
    }
  }

  @media only screen and (max-width: 768px) {
  }
`;
