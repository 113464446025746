import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 24px 0;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100px;
  top: 0;
`;

export const Logo = styled.div`
  -webkit-backdrop-filter: blur(15px);
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  justify-content: center;
  align-content: center;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 110px;
  width: 100%;
  h1 {
    font-size: var(--super);
  }
`;

export const Box = styled.div`
  cursor: pointer;
  width: 100%;

  svg {
    padding: 15px;
    cursor: pointer;
    color: var(--bright);
    font-size: 20px;
    transition: all 0.3s ease;

    :hover {
      color: var(--primary);
    }
  }

  .bar {
    background-color: var(--bright);
    margin-top: 20px;
    height: 100px;
    width: 2px;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      background-color: red;
    }

    svg,
    .bar {
      display: none;
    }
  }
`;
