import { useLayoutEffect, useState } from "react";

export const useScrollspy = (listOfRefs) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;
      const listOfHeights = [];

      const activeMenu = listOfRefs
        .map((ref, index) => {
          if (!ref.current) return;
          const thisHeight = ref.current.getBoundingClientRect().height;
          listOfHeights[index] = thisHeight;

          const actualHeight = listOfHeights
            .slice(0, index + 1)
            .reduce((acc, value) => acc + value);

          return { index, scroll, actualHeight };
        })
        .find(({ scroll, actualHeight }) => scroll <= actualHeight - 200);

      setActiveId(activeMenu.index);
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [listOfRefs]);

  return activeId;
};
