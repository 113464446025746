import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  position: relative;
  padding: 100px;
  width: 100%;
  z-index: 20;

  @media only screen and (max-width: 768px) {
    padding: 25px;
    width: 100%;
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
    margin: 0;
  }
`;

export const Banner = styled.div`
  position: relative;
  flex: 1;
`;

export const Info = styled.div`
  position: relative;
  width: 100%;

  span {
    font-size: var(--large);
    font-weight: bold;
  }

  h1 {
    font-size: var(--title);
    color: var(--light);
    margin-top: -10px;
    margin-bottom: 100px;
  }

  img {
    width: 120%;
    margin-left: -100px;
    position: absolute;
    top: 100px;
    z-index: 5;
  }

  @media only screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

export const Jorneys = styled.div`
  width: 100%;

  .job {
    background-color: var(--background);
    transition: var(--fast);
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    width: 270px;

    :hover {
      background-color: var(--skeleton);
    }

    h1 {
      font-size: var(--big);
      color: var(--light);
      margin-bottom: 10px;
      transition: var(--fast);
      font-weight: 500;
    }

    p {
      font-size: var(--medium);
      color: var(--bright);
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 768px) {
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .job {
      background-color: var(--skeleton);
      height: 250px;
      margin-right: 20px;
    }
  }
`;
