import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  position: relative;
  margin-top: 100vh;
  padding: 100px;
  width: 100%;
  z-index: 20;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
    margin: 0;
  }
`;

export const Banner = styled.div`
  align-items: center;
  position: relative;
  flex: 1;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  width: 50%;

  span {
    font-size: var(--large);
    font-weight: bold;
  }

  h1 {
    font-size: var(--title);
    color: var(--light);
    margin-top: -10px;
  }

  p {
    color: var(--bright);
    font-size: var(--large);
    margin: 26px 0;
  }

  button {
    background-color: var(--border);
    border: 2px solid var(--border);
    font-size: var(--large);
    text-decoration: none;
    color: var(--light);
    padding: 15px 35px;
    border-radius: 10px;
    font-weight: 600;
    margin-top: 10px;

    :hover {
      background-color: var(--primary);
      border: 2px solid var(--primary);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;

    h1 {
      font-size: var(--subtitle);
    }

    button {
      padding: 10px 20px;
      font-size: var(--medium);
    }
  }
`;

export const Portrait = styled.div`
  position: relative;

  .me {
    position: relative;
    border-radius: 5px;
    width: 350px;
    z-index: 2;
  }

  .box {
    background-color: var(--primary);
    position: absolute;
    border-radius: 50px;
    width: 350px;
    height: 400px;
    z-index: 1;
    top: 77.5px;
    right: 20px;
  }

  .dots {
    position: absolute;
    bottom: -10px;
    right: -30px;
    width: 100px;
    z-index: 3;
  }

  @media only screen and (max-width: 768px) {
    transform: scale(0.9) translate(4%, 0%);
  }
`;
