import {
  faDribbble,
  faGithub,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { email, github, linkedin, whatsapp, dribbble } from "config";
import { goTop } from "lib/functions";
import { Box, Container, Logo } from "./styles";

const LeftMedia = () => {
  return (
    <Container
      name="container"
      className="flex_cls"
      data-aos="fade-up"
      data-aos-delay="2500"
    >
      <Logo
        name="logo"
        className="flex_cls"
        data-aos="fade-in"
        data-aos-delay="3000"
      >
        <h1 className="gradient" onClick={() => goTop()}>
          J
        </h1>
      </Logo>
      <Box name="box" className="flex_cls">
        <a href={github} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a href={linkedin} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a href={dribbble} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faDribbble} />
        </a>
        <a href={whatsapp} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <a href={email} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <div className="bar" />
      </Box>
    </Container>
  );
};

export default LeftMedia;
