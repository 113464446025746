import { Banner, Container, Info, Wrapper, Project } from "./styles";
import { iguacu, jay, medical } from "videos";
import Presentation from "components/Presentation";
import { useState } from "react";

const Projects = ({ projectsRef, language }) => {
  const { subtitle, title, showcases } = language.projects;
  const [presentation, setPresentation] = useState({
    project: {
      name: "",
      description: "",
      video: "",
      site: "",
    },
    show: false,
  });

  const handleProjectChange = (projectName) => {
    switch (projectName) {
      case "jay":
        return setPresentation({
          project: {
            name: showcases[0].title,
            description: showcases[0].description,
            video: jay,
            site: showcases[0].site,
          },
          show: true,
        });

      case "iguacu":
        return setPresentation({
          project: {
            name: showcases[1].title,
            description: showcases[1].description,
            video: iguacu,
            site: showcases[1].site,
          },
          show: true,
        });

      case "medical":
        return setPresentation({
          project: {
            name: showcases[2].title,
            description: showcases[2].description,
            video: medical,
            site: showcases[2].site,
          },
          show: true,
        });
      default:
        break;
    }
  };

  return (
    <>
      <Container name="container" ref={projectsRef}>
        <Wrapper name="wrapper">
          <Banner name="banner" className="flex_ccc">
            <Info name="info" data-aos="fade-up" data-aos-delay="250">
              <span className="gradient">{title}</span>
              <h1>{subtitle}</h1>
            </Info>
            <Project
              name="projects"
              className="flex_cb"
              data-aos="fade-up"
              data-aos-delay="250"
              onClick={() => handleProjectChange("jay")}
            >
              <video src={jay} muted />
              <div className="details ">
                <p className="gradient">{showcases[0].subtitle}</p>
                <h1>{showcases[0].title}</h1>
                <ul>
                  {showcases[0].tech.map((tec) => (
                    <li key={tec}>{tec}</li>
                  ))}
                </ul>
                <span>{showcases[0].description}</span>
              </div>
            </Project>
            <Project
              name="projects"
              className="flex_cb"
              data-aos="fade-up"
              data-aos-delay="250"
              onClick={() => handleProjectChange("iguacu")}
            >
              <div className="details tx_left">
                <p className="gradient">{showcases[1].subtitle}</p>
                <h1>{showcases[1].title}</h1>
                <ul>
                  {showcases[1].tech.map((tec) => (
                    <li key={tec}>{tec}</li>
                  ))}
                </ul>
                <span>{showcases[1].description}</span>
              </div>
              <video src={iguacu} muted />
            </Project>
            <Project
              name="projects"
              className="flex_cb"
              data-aos="fade-up"
              data-aos-delay="250"
              onClick={() => handleProjectChange("medical")}
            >
              <video src={medical} muted />
              <div className="details">
                <p className="gradient">{showcases[2].subtitle}</p>
                <h1>{showcases[2].title}</h1>
                <ul>
                  {showcases[2].tech.map((tec) => (
                    <li key={tec}>{tec}</li>
                  ))}
                </ul>
                <span>{showcases[2].description}</span>
              </div>
            </Project>
          </Banner>
        </Wrapper>
      </Container>
      {presentation.show && (
        <Presentation
          presentation={presentation}
          setPresentation={setPresentation}
        />
      )}
    </>
  );
};

export default Projects;
