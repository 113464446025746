import { Container, Wrapper } from "./styles";

const Footer = () => {
  return (
    <Container name="container">
      <Wrapper name="wrapper">
        <p>Copyright © 2022 Jordão Qualho</p>
      </Wrapper>
    </Container>
  );
};

export default Footer;
