import english from "Languages/english";
import About from "components/About";
import Contact from "components/Contact";
import Experience from "components/Experience";
import Footer from "components/Footer";
import Hero from "components/Hero";
import LeftMedia from "components/LeftMedia";
import LoadingPage from "components/LoadingPage";
import Navigation from "components/Navigation";
import Projects from "components/Projects";
import Translator from "components/Translator";
import { useScrollspy } from "hooks/useScrollspy";
import useStorage from "hooks/useStorage";
import { goTop } from "lib/functions";
import { useRef } from "react";
import { Container } from "./styles";

const HomePage = () => {
  const [language, setLanguage] = useStorage("language", english);

  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const experienceRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  const listOfRefs = [heroRef, aboutRef, experienceRef, projectsRef, contactRef];

  const activeMenu = useScrollspy(listOfRefs);

  const scrollTo = (target) => {
    if (target === 0) return goTop();
    listOfRefs[target].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <LoadingPage name="loadingPage" />
      <Container name="container">
        <LeftMedia name="menu" />
        <Translator setLanguage={setLanguage} language={language} />
        <Navigation name="navigation" scrollTo={scrollTo} activeMenu={activeMenu} language={language} />
        <Hero name="hero" heroRef={heroRef} scrollTo={scrollTo} language={language} />
        <About name="about" aboutRef={aboutRef} language={language} />
        <Experience name="experience" experienceRef={experienceRef} language={language} />
        <Projects name="projects" projectsRef={projectsRef} language={language} />
        <Contact name="contact" contactRef={contactRef} language={language} />
        <Footer name="footer" />
      </Container>
    </>
  );
};

export default HomePage;
