import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  position: relative;
  width: 100%;
  z-index: 20;
`;

export const Wrapper = styled.div`
  flex-direction: column;
  padding: 20px;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  p {
    text-align: center;
    color: var(--bright);
    font-size: var(--small);
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
  }
`;
