import english from "Languages/english";
import portuguese from "Languages/portuguese";
import { Container } from "./styles";

const Translator = ({ language, setLanguage }) => {
  const activeLanguage = (button) => (language.name === button ? "active" : "");

  const handleLanguage = (buttonLanguage) => {
    setLanguage(buttonLanguage === "pt" ? portuguese : english);
    window.location.reload();
  };

  return (
    <Container name="container">
      <button className={activeLanguage("pt")} onClick={() => handleLanguage("pt")}>
        PT
      </button>
      <button className={activeLanguage("en")} onClick={() => handleLanguage("en")}>
        EN
      </button>
    </Container>
  );
};

export default Translator;
