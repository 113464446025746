import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 200;
`;

export const Wrapper = styled.div`
  flex-direction: column;
  padding: 50px 0;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  p {
    text-align: flex-start;
    color: var(--light);
    font-size: var(--medium);
    margin-bottom: 40px;
  }

  .skeleton {
    background-color: black;
    border-radius: 10px;
    position: absolute;
    height: 565px;
    width: 1000px;
    z-index: 320;
    top: 195px;

    display: grid;
    place-items: center;

    .circle {
      width: 200px;
      height: 200px;
      border: 10px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top-color: var(--light);
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  video {
    border-radius: 10px;
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
  }
`;

export const Header = styled.div`
  align-items: center;

  h1 {
    font-size: var(--title);
    color: var(--light);
    margin-top: -10px;
  }

  button {
    transition: var(--normal);
    border-radius: 5px;
    padding: 5px 30px;
    margin-left: 20px;

    :hover {
      opacity: 0.5;
    }
  }

  .view {
    background-color: var(--primary);
    color: var(--light);
  }
`;
