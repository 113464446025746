import common from "Languages/common";
import { curriculum } from "config";
import { circles3, me } from "imgs";
import { Banner, Container, Info, Portrait, Wrapper } from "./styles";

const About = ({ aboutRef, language }) => {
  const { title, subtitle, description, work, stack, filler, button } = language.about;

  return (
    <Container name="container" ref={aboutRef}>
      <Wrapper name="wrapper">
        <Banner name="banner" className="flex_cb" data-aos="fade-up" data-aos-delay="150">
          <Info name="info">
            <span className="gradient">{title}</span>
            <h1>{subtitle}</h1>
            <p>
              {description.firstText}
              <span style={{ color: "var(--light)", fontWeight: "normal" }}>{description.span}</span>
              {description.secondText}
              <br />
              <br /> {work.firstText}
              <a className="gradient" href="https://www.voyagerportal.com/" target="_blank" rel="noreferrer">
                {common.actualJob}
              </a>
              {work.secondText}
              <br /> {stack}
              {common.myStack}
              <br /> {filler}
            </p>
            <a href={curriculum} target="_blank" rel="noreferrer">
              <button>{button}</button>
            </a>
          </Info>
          <Portrait>
            <img src={me} width={350} height={478} alt="myPhoto" className="me" />
            <img width={100} height={46} src={circles3} alt="dots" className="dots" />
            <div className="box" />
          </Portrait>
        </Banner>
      </Wrapper>
    </Container>
  );
};

export default About;
