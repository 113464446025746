import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  position: relative;
  padding: 100px;
  width: 100%;
  z-index: 20;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
    margin: 0;
  }
`;

export const Banner = styled.div`
  position: relative;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  margin-bottom: 50px;

  span {
    font-size: var(--large);
    font-weight: bold;
  }

  h1 {
    font-size: var(--title);
    color: var(--light);
    margin-top: -10px;
  }

  p {
    font-size: var(--large);
    color: var(--bright);
    margin: 26px 0;
    width: 450px;
  }

  @media only screen and (max-width: 768px) {
    p {
      width: auto;
    }
  }
`;

export const Direct = styled.div`
  position: relative;
  width: 100%;

  .media {
    transform: translate(50%, -50%);
    position: absolute;
    right: 50%;

    svg {
      padding: 0 35px;
      cursor: pointer;
      color: var(--light);
      font-size: 24px;
      transition: all 0.3s ease;
      background-color: var(--background);

      :hover {
        color: var(--primary);
      }
    }
  }
  .bar {
    background-color: var(--light);
    margin-top: 50px;
    height: 2px;
    width: 100%;
  }
`;

export const Modal = styled.div`
  background-color: var(--modal);
  box-shadow: var(--basicShadow);
  border-radius: 15px;
  text-align: center;
  position: relative;
  width: 380px;
  padding: 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
    margin-top: 50px;
  }

  h1 {
    font-size: var(--big);
    color: var(--light);
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    font-size: var(--medium);
    color: var(--bright);
    margin-bottom: 30px;
  }

  button {
    background-color: var(--primary);
    color: var(--light);
    padding: 15px 10px;
    border-radius: 10px;
    font-size: var(--medium);
    font-weight: 500;
    margin-top: 50px;
    width: 100%;

    img {
      width: 17px;
      margin-left: 10px;
    }

    :hover {
      opacity: 0.75;
    }
  }

  input,
  textarea {
    border-bottom: 1px solid var(--light);
    background-color: var(--modal);
    transition: var(--fast);
    color: var(--light);
    resize: none;
    padding: 10px 0;
    margin: 10px 0;
    width: 100%;

    ::placeholder {
      color: var(--bright);
      font-size: var(--medium);
    }

    :focus {
      border-bottom: 1px solid var(--primary);
    }
  }

  .dots {
    position: absolute;
    transform: rotate(90deg);
    right: -50px;
    width: 100px;
    top: 50px;
  }

  .contact {
    position: absolute;
    bottom: -10px;
    left: -15px;
    width: 35px;
  }
`;

export const Overlay = styled.div`
  background: linear-gradient(180deg, #16161a 25%, #3d2c73 200%);
  position: absolute;
  height: 500px;
  width: 100%;
  z-index: 1;
  bottom: 0;
`;
