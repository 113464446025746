import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  min-height: 100vh;
  width: 100vw;

  @media only screen and (max-width: 768px) {
    overflow: hidden;
  }
`;
