import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { whatsapp, email } from "config";
import { contact, dots, send } from "imgs";
import { Banner, Container, Info, Wrapper, Modal, Direct } from "./styles";

const Contact = ({ contactRef, language }) => {
  const { title, subtitle, description, modal, button } = language.contact;

  return (
    <Container name="container" ref={contactRef}>
      <Wrapper name="wrapper">
        <Banner
          name="banner"
          className="flex_cb"
          data-aos="fade-up"
          data-aos-delay="250"
        >
          <Info name="info">
            <span className="gradient">{title}</span>
            <h1>{subtitle}</h1>
            <p>{description}</p>
            <Direct name="direct">
              <div className="media">
                <a href={whatsapp} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
                <a href={email} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ paddingLeft: 0 }}
                  />
                </a>
              </div>
              <div className="bar" />
            </Direct>
          </Info>
          <Modal name="modal" className="flex_ccc">
            <h1>{modal.title}</h1>
            <p>{modal.description}</p>
            <input type="text" placeholder={modal.inputs[0]} />
            <input type="text" placeholder={modal.inputs[1]} />
            <textarea rows="5" type="text" placeholder={modal.inputs[2]} />
            <button className="flex_cc">
              {button}
              <img src={send} width={17} height={17} alt="send" />
            </button>
            <img src={dots} alt="dots" className="dots" />
            <img src={contact} alt="contact" className="contact" />
          </Modal>
        </Banner>
      </Wrapper>
    </Container>
  );
};

export default Contact;
