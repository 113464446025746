import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 24px 0;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100px;
  right: 0;
  top: 0;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`;

export const Box = styled.div`
  width: 100%;

  .active {
    background-color: var(--primary);
    border: 1px solid var(--secundary);
    transform: scale(1.5);

    :hover {
      background-color: var(--primary);
    }
  }
`;

export const Dots = styled.div`
  background-color: var(--border);
  transition: var(--fast);
  border-radius: 50px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  height: 12px;
  width: 12px;

  :hover {
    background-color: var(--light);

    :after {
      opacity: 1;
    }
  }

  :after {
    content: "${(props) => props.name}";
    background-color: var(--primary);
    transition: var(--normal);
    white-space: nowrap;
    color: var(--light);
    position: absolute;
    border-radius: 5px;
    padding: 2px 10px;
    right: 20px;
    opacity: 0;
    top: -3px;
  }
`;
