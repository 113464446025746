export default {
  name: "pt",
  menus: ["início", "sobre min", "experiências", "projetos", "contato"],
  hero: {
    title: "Olá, eu sou o ",
    subtitle:
      "Sou um desenvolvedor fullstack especializado em construir (e às vezes criar designs) ótimas experiencias digitais.",
    buttons: {
      download: "Baixar CV",
      projects: "Ver projetos",
    },
  },
  about: {
    title: "SOBRE MIN",
    subtitle: "Problema? Eu gosto!",
    description: {
      firstText:
        "Meu nome é Jordão Qualho, sou um desenvolvedor fullstack desde 2020, em outras palavras, ",
      span: "eu amo resolver problemas",
      secondText:
        " e depois ver tudo funcionando no final! Sou apaixonado por rpg, música e um bom xadrez.",
    },
    work: {
      firstText: "💼 Atuando na ",
      secondText: " como Engenheiro de Software.",
    },
    stack: "🖥️ Principal Stack: ",
    filler: "🚀 Sempre aprendendo novas tecnologias.",
    button: "Baixe meu CV",
  },
  experience: {
    title: "EXPERIÊNCIA",
    subtitle: "Minha jornada até aqui",
    jobs: [
      {
        year: "2021",
        title: "CRIA Studio",
        description:
          "Desenvolvi um software de renderização arquitetônica que permitia aos usuários criar imagens bidimensionais de um projeto arquitetônico usando Next.js e Node.js, com integração com MongoDB e AWS S3 para armazenamento de imagens.",
      },
      {
        year: "2022",
        title: "SOMA Group",
        description:
          "Colaborei com uma equipe para redesenhar e desenvolver uma plataforma de comércio eletrônico ao vivo para marcas de roupas como a FARM. Um dos desafios era transferir toda a infraestrutura da Amazon Web Services para a plataforma de nuvem do Google.",
      },
      {
        year: "Atualmente",
        title: "Voyager Portal",
        description:
          "Trabalhando em colaboração com designers e equipe de gerenciamento para desenvolver uma plataforma SaaS líder do setor, avançando o setor de energia em direção a uma cadeia de suprimentos marítima transparente e sem desperdício com o Vue.js.",
      },
    ],
  },
  projects: {
    title: "PROJECTS",
    subtitle: "O que eu já fiz",
    showcases: [
      {
        title: "Jay Assistente",
        subtitle: "Projeto real",
        site: "",
        tech: ["React.js", "Redux", "Node.js", "Jest"],
        description:
          "Software de planejamento de recursos empresariais (ERP) desenvolvido para ajudar a empresa a gerenciar as atividades de negócios do dia-a-dia, como contabilidade, compras, contratos e emissão de notas fiscais. Este software também inclui um painel de controle para auxiliar na gestão do desempenho empresarial.",
      },
      {
        title: "Iguaçu Contábil",
        subtitle: "Projeto real",
        site: "https://www.iguacucontabil.com.br/",
        tech: ["React.js", "Amazon S3", "Node.js", "MongoDB"],
        description:
          "Site corporativo completo criado especificamente para uma empresa de contabilidade mostrar aos seus clientes a sua história, propósito e serviços. O site também foi desenvolvido com integração com Clarity, o que torna possível medir e analisar a interação dos clientes com o site.",
      },
      {
        title: "Healhty Carely",
        subtitle: "Projeto demonstrativo",
        site: "https://healhty-carely.vercel.app/",
        tech: ["React.js", "Styled Components"],
        description:
          "Uma landing page moderna para uma clínica médica fictícia com foco principal na experiência do usuário para levar os clientes a um produto, serviço ou oferta específica e incentivá-los a agir.",
      },
    ],
  },
  contact: {
    title: "CONTATO",
    subtitle: "Vamos conversar?",
    description:
      "Embora eu não esteja procurando novas oportunidades no momento, minha caixa de entrada está sempre aberta. Se você tem uma pergunta ou apenas quer dizer oi, farei o possível para responder a você!",
    modal: {
      title: "Contato rápido",
      description:
        "Para estabelecer um primeiro contacto rápido, basta preencher estes campos.",
      inputs: ["Email", "Assunto", "Mensagem"],
      button: "Enviar",
    },
  },
};
