import { Box, Container, Dots } from "./styles";

const Navigation = ({ activeMenu, scrollTo, language }) => {
  return (
    <Container
      name="container"
      className="flex_cc"
      data-aos="fade-left"
      data-aos-delay="3000"
    >
      <Box name="box" className="flex_ccc">
        {Array(5)
          .fill(5)
          .map((_, index) => {
            return (
              <Dots
                className={`${activeMenu === index ? "active" : ""}`}
                onClick={() => scrollTo(index)}
                name={language.menus[index]}
                key={index}
              />
            );
          })}
      </Box>
    </Container>
  );
};

export default Navigation;
