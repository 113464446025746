const curriculum = "https://drive.google.com/u/0/uc?id=1BVMEJqPUN_510m5oKWby1gkv2kbhH7fI&export=download";

const email = "mailto:jordaoqualho@gmail.com";
const linkedin = "https://www.linkedin.com/in/jordaoqualho/";
const dribbble = "https://dribbble.com/JordaoQualho";
const whatsapp = "https://api.whatsapp.com/send?l=pt_BR&phone=55044997000617";
const github = "https://github.com/jordaoqualho";

const environment = process.env.REACT_APP_ENVIRONMENT;

export { curriculum, dribbble, email, environment, github, linkedin, whatsapp };
