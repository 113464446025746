export default {
  name: "en",
  menus: ["home", "about", "experiences", "projects", "contact"],
  hero: {
    title: "Hello world, I'm ",
    subtitle:
      "I’m a full stack developer specializing in building (and occasionally designing) exceptional digital experiences.",
    buttons: {
      download: "Download CV",
      projects: "View projects",
    },
  },
  about: {
    title: "ABOUT ME",
    subtitle: "Problem? I like it!",
    description: {
      firstText: `I am Jordão Qualho, a fullstack developer since 2020, in other
              words, `,
      span: "I love to solve problems",
      secondText:
        " and to see everything working in the end! I'm passionate about rpg, classic music and a good chess game.",
    },
    work: {
      firstText: "💼 Working at ",
      secondText: " as a Software Engineer.",
    },
    stack: "🖥️ Main Stack: ",
    filler: "🚀 Always learning new technologies.",
    button: "Download my CV",
  },
  experience: {
    title: "EXPERIENCE",
    subtitle: "My Jorney so far",
    jobs: [
      {
        year: "2021",
        title: "CRIA Studio",
        description:
          "Developed an architectural rendering software that allowed users to create two-dimensional images of an architectural design using Next.js and Node.js, with integration with MongoDB and AWS S3 for image storage.",
      },
      {
        year: "2022",
        title: "SOMA Group",
        description:
          "Collaborated with team to redesign and develop a live e-commerce platform for clothing brands such as FARM. One of the challenges was to transfer the whole infrastructure from Amazon Web Services to the Google cloud platform.",
      },
      {
        year: "Present",
        title: "Voyager Portal",
        description:
          "Working closely with designers and management team to develop an industry-leading SaaS platform, advancing the energy industry towards a transparent and zero-waste marine supply chain with Vue.js.",
      },
    ],
  },
  projects: {
    title: "PROJECTS",
    subtitle: "Some things I've Built",
    showcases: [
      {
        title: "Jay Assistent",
        subtitle: "Featured Project",
        site: "",
        tech: ["React.js", "Redux", "Node.js", "Jest"],
        description:
          "A Enterprise resource planning (ERP) software developed to help the company to manage day-to-day business activities such as accounting, procurement, contract and fiscal notes emission. This software also includes a dashboard to help the enterprise performance management.",
      },
      {
        title: "Iguaçu Contabil",
        subtitle: "Featured Project",
        site: "https://www.iguacucontabil.com.br/",
        tech: ["React.js", "Amazon S3", "Node.js", "MongoDB"],
        description:
          "A full company website created specifically for an accounting enterprise to show their clients the history, purpose and services of the company. The website also was developed with integration with clarity to we can measure and analyses the interaction of the clients with the pages.",
      },
      {
        title: "Healhty Carely",
        subtitle: "Showcase Project",
        site: "https://healhty-carely.vercel.app/",
        tech: ["React.js", "Styled Components"],
        description:
          "A showcase of a modern landing page for a medical clinic with the main focus on the user experience to lead customers to a specific product, service, or offer and encourage them to take action.",
      },
    ],
  },
  contact: {
    title: "CONTACT",
    subtitle: "Get in Touch",
    description:
      "Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!",
    modal: {
      title: "Contact rapide",
      description:
        "In order to establish a quick first contact, simply fill in these fields.",
      inputs: ["Email", "Subject", "Message"],
      button: "Send",
    },
  },
};
