import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--background);
  position: relative;
  padding: 100px;
  width: 100%;
  z-index: 20;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 60px 25px;
    width: 100%;
  }
`;

export const Banner = styled.div`
  position: relative;
  flex: 1;
`;

export const Info = styled.div`
  span {
    font-size: var(--large);
    font-weight: bold;
  }

  h1 {
    font-size: var(--title);
    color: var(--light);
    margin-top: -10px;
  }

  p {
    color: var(--bright);
    font-size: var(--large);
    margin: 26px 0;
    width: 650px;
  }
`;

export const Project = styled.div`
  border: 1px solid var(--skeleton);
  transition: var(--fast);
  background: var(--skeleton);
  border-radius: 10px;
  margin-top: 100px;
  cursor: pointer;
  padding: 15px;
  width: 100%;

  :hover {
    border: 1px solid var(--secundary);
  }

  video {
    width: 550px;
    border-radius: 5px;
    position: relative;
    transition: var(--normal);
    z-index: 20;

    :hover {
      ${"" /* transform: scale(1.6) translate(15%); */}
    }
  }

  .details {
    color: white;
    align-items: flex-end;
    text-align: right;
    padding: 10px 10px 10px 30px;

    P {
      font-size: var(--medium);
      margin-bottom: -10px;
      font-weight: 600;
    }

    h1 {
      font-size: var(--subtitle);
      margin-bottom: 5px;
    }

    ul {
      justify-content: flex-end;
      margin-bottom: 15px;
      list-style: none;
      display: flex;
      padding: 0;
      gap: 10px;

      li {
        background-color: var(--background);
        font-size: var(--small);
        border-radius: 5px;
        padding: 3px 10px;
        display: inline;
      }
    }

    span {
      font-size: var(--medium);
      color: var(--bright);
      font-weight: 300;
    }
  }

  .tx_left {
    padding: 10px 30px 10px 10px;
    text-align: left;

    ul {
      justify-content: flex-start;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .details {
      padding: 5px;
      text-align: left;

      order: 1;

      p {
        margin-top: 20px;
      }
    }

    video {
      width: 100%;
    }
  }
`;
