import { plane } from "imgs";
import { Banner, Container, Info, Wrapper, Jorneys } from "./styles";

const Experience = ({ experienceRef, language }) => {
  const { title, subtitle, jobs } = language.experience;
  return (
    <Container name="container" ref={experienceRef}>
      <Wrapper name="wrapper">
        <Banner
          name="banner"
          className="flex_cs"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <Info name="info">
            <span className="gradient">{title}</span>
            <h1>{subtitle}</h1>

            <Jorneys name="jorneys" className="flex_cb">
              <a
                href="https://www.linkedin.com/company/criastudio/"
                target="_blank"
                rel="noreferrer"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <div className="job">
                  <span className="gradient">{jobs[0].year}</span>
                  <h1>{jobs[0].title}</h1>
                  <p>{jobs[0].description}</p>
                </div>
              </a>

              <a
                href="https://www.somagrupo.com.br/en"
                target="_blank"
                rel="noreferrer"
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <div className="job">
                  <span className="gradient">{jobs[1].year}</span>
                  <h1>{jobs[1].title}</h1>
                  <p>{jobs[1].description}</p>
                </div>
              </a>

              <a
                href="https://www.voyagerportal.com/"
                target="_blank"
                rel="noreferrer"
                data-aos="fade-up"
                data-aos-delay="450"
              >
                <div className="job">
                  <span className="gradient">{jobs[2].year}</span>
                  <h1>{jobs[2].title}</h1>
                  <p>{jobs[2].description}</p>
                </div>
              </a>
            </Jorneys>
            <img src={plane} alt="plane" />
          </Info>
        </Banner>
      </Wrapper>
    </Container>
  );
};

export default Experience;
