import { useState } from "react";
import { Container, Wrapper, Header } from "./styles";

const Presentation = ({ presentation, setPresentation }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { project } = presentation;
  return (
    <Container name="container" data-aos="fade-in" data-aos-delay="200">
      <Wrapper name="wrapper">
        <Header className="flex_cb" data-aos="fade-up" data-aos-delay="450">
          <h1>{project.name}</h1>
          <div>
            {project.site && (
              <a
                href={project.site}
                target="_blank"
                rel="noreferrer"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <button className="view">View</button>
              </a>
            )}
            <button
              onClick={() => setPresentation({ ...presentation, show: false })}
            >
              Close
            </button>
          </div>
        </Header>
        <p data-aos="fade-in" data-aos-delay="700">
          {project.description}
        </p>

        {!videoLoaded && (
          <div className="skeleton">
            <div className="circle" />
          </div>
        )}

        <video
          src={project.video}
          muted
          autoPlay
          loop
          data-aos="fade-in"
          data-aos-delay="700"
          onLoadedData={() => setVideoLoaded(true)}
        />
      </Wrapper>
    </Container>
  );
};

export default Presentation;
