import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
`;

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  width: 1000px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0 25px;
    width: 100%;
    margin: 0;
  }
`;

export const Banner = styled.div`
  position: relative;
  flex: 1;
`;

export const Info = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: var(--super);
    color: var(--light);

    span {
      color: var(--primary);
    }
  }

  p {
    color: var(--bright);
    font-size: var(--big);
    margin: 26px 0;
    max-width: 900px;
  }

  a {
    text-decoration: none;
    color: var(--light);
    transition: var(--fast);

    :hover {
      color: var(--secundary);
    }
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: var(--title);
    }
    p {
      font-size: var(--large);
    }
  }
`;

export const Buttons = styled.div`
  margin-top: 50px;

  button {
    width: 200px;
  }

  .download {
    background-color: var(--primary);
    color: var(--basic);
    padding: 15px 35px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;

    :hover {
      opacity: 0.75;
    }
  }

  .projects {
    background-color: var(--skeleton);
    color: var(--basic);
    padding: 15px 35px;
    border-radius: 10px;
    font-size: var(--large);
    font-weight: 600;
    margin-left: 20px;

    :hover {
      opacity: 0.75;
    }
  }

  @media only screen and (max-width: 768px) {
    .projects,
    .download {
      width: 150px;
      font-size: var(--medium);
      padding: 15px 20px;
    }
  }
`;

export const Overlay = styled.div`
  background: linear-gradient(
    180deg,
    var(--background) 25%,
    var(--primary) 200%
  );
  position: absolute;
  height: 500px;
  width: 100%;
  z-index: 1;
  bottom: 0;

  @media only screen and (max-width: 768px) {
  }
`;
